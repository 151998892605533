<template>
	<div id="student_placement_detail" v-loading="loading" :style="{height: height + 'px'}">
		<div class="head u-f-item u-f-jsb">
            <div>{{info.name}}分班</div>
            <el-button size="small" style="width: 100px" @click="save">保存</el-button>
        </div>
        <div class="content">
            <div class="title u-f-item u-f-jsb">
                <div>
                    共{{total}}条数据，
                    <span class="btn" @click="selectAll">选择全部</span>
                </div>
                <div class="u-f-item">
                    <el-input placeholder="请输入内容" v-model="sousuo" size="small" style="margin-right: 10px;">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                    <el-button size="small" @click="VisibleTap(1)">添加学生</el-button>
                    <el-button size="small">批量更新</el-button>
                    <el-button size="small">批量录入</el-button>
                    <el-button size="small">导出</el-button>
                </div>
            </div>
            <el-table
                :data="list"
                height="80%"
                ref="multipleTable"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange"
                style="width: 100%;border: 1px solid #EEEEEE;"
                key="2"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="序号" type="index"  width="150" align="center"></el-table-column>
                <el-table-column prop="class_id" label="班级" align="center">
                    <template slot-scope="scope">
                        <div>
                            <el-select size="small" v-model="scope.row.class_id" placeholder="请选择班级">
                                <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="realname" label="姓名" align="center"></el-table-column>
                <el-table-column prop="sex_text" label="性别" align="center"></el-table-column>
                <el-table-column prop="endtime" label="学号" align="center">
                    <template slot-scope="scope">
                        <div>
                            <el-input placeholder="请输入学号" v-model="scope.row.class_num" size="small"></el-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="student_num" label="学籍号" align="center"></el-table-column>
                <el-table-column prop="remark" label="备注" align="center">
                    <template slot-scope="scope">
                        <div>
                            <el-input placeholder="请输入备注" v-model="scope.row.remark" size="small"></el-input>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="u-f-item u-f-jsb" style="padding: 15px 0;">
                <el-button type="danger" plain size="mini" @click="delStu">删除</el-button>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="page"
                    :page-size="limit"
                    layout="total, prev, pager, next"
                    :total="total"
                ></el-pagination>
            </div>
        </div>
        <el-dialog :title="VisibleTitle" :visible.sync="addVisible" width="700px" :before-close="handleClose">
            <div class="header u-f-item u-f-jsb">
                <div class="u-f-item">
                    <span>共{{s_total}}人</span>
                    <el-select size="small" v-model="year" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeYear">
                        <el-option v-for="item in yearList" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </div>
                <div>
                    <el-input placeholder="请输入内容" v-model="sousuo" size="small" style="margin-right: 10px;">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                </div>
            </div>
            <div class="con-box">
                <div class="u-f-item" v-for="(item, index) in studentList" :key="index" :class="[item.disabled?'none':'can', item.selected?'selected':'']" @click="choose(item.id)">
                    <div>{{item.realname}}</div>
                    <div>{{item.sex_text}}</div>
                </div>
            </div>
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange1"
                    :current-page.sync="s_page"
                    :page-size="limit"
                    layout="total, prev, pager, next"
                    :total="s_total"
                ></el-pagination>
            </div>
            <span slot="footer" class="dialog-footer u-f-item u-f-jsb">
                <el-button @click="closeVisible" size="small">取 消</el-button>
                <el-button type="primary" size="small" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		
	},
	data() {
		return {
            height: 500,
			loading: false,
            id: '',
            info: {},
            page: 1,
            limit: 10,
            total: 0,
            list: [],
            VisibleTitle: '',
            addVisible: false,
            student_ids: [],
            year: '',
            yearList: [],
            s_page: 1,
            s_total: 0,
            studentList: [],
            sousuo: '',
            classList: [{id: 0, name: '请选择'}],
            ids: []
		};
	},
	created() {},
	mounted() {
        this.height = document.documentElement.clientHeight || document.body.clientHeight;
		this.id = this.$route.query.id;
        this.getInfo()
	},
	methods: {
        // 关闭弹窗
        handleClose (done) {
            this.studentList = [];
            done()
        },
        handleSelectionChange(val) {
            let arr = [];
            for(let i in val){
                arr.push(val[i].id);
            }
            this.ids = arr;
            console.log(this.ids)
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getList()
        },
        handleCurrentChange1(val) {
            this.s_page = val;
            this.getStudentList()
        },
        filterTag(value, row) {
            return row.tag === value;
        },
        filterHandler(value, row, column) {
            const property = column['property'];
            return row[property] === value;
        },
        selectAll(){
            let self = this;
            this.list.forEach(row => {
                self.$refs.multipleTable.toggleRowSelection(row, true);
            });
        },
        // 弹窗
        VisibleTap(type,item){
            this.type = type;
            if(type==1){
                this.VisibleTitle = '添加学生';
                this.getYearList()
            }else if(type==2){
                
            }
            this.addVisible = true;
        },
        // 关闭弹窗
        closeVisible(){
            this.initialize()
            this.addVisible = false;
        },
        // 学年列表
        getYearList(){
            this.$api.student.entYear().then(res=>{
                if(res.data.code==1){
                    this.yearList = res.data.data;
                    this.year = this.yearList[0].value;
                    this.getStudentList()
                }
            })
        },
        changeYear(e){
            this.year = e;
            this.s_page = 1;
            this.studentList = [];
            this.getStudentList()
        },
		getInfo(){
            this.$api.student.detailCsd({
                id: this.id,
            }).then(res=>{
                if (res.data.code == 1) {
                    this.info = res.data.data;
                    this.getList();
                    this.getClassList()
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        getList(){
            this.$api.student.ccsdList({
                distribution_id: this.id,
            }).then(res=>{
                if (res.data.code == 1) {
                    this.list = res.data.data.rows;
                    this.total = res.data.data.total
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        // 班级列表
        getClassList(){
           this.$api.setting.getClassList({filter: JSON.stringify({'school_campus_id': this.info.campus_id, 'grade_id': this.info.grade_id})}).then(res=>{
               this.classList = res.data.rows;
           })
        },
        // 可分班学生列表
        getStudentList(){
            let data = {
                distribution_id: this.id,
                ent_year: this.year,
                page: this.s_page,
                limit: this.limit
            }
            this.$api.student.ccsdStuedentList(data).then(res => {
                if (res.data.code == 1) {
                    let list = res.data.data.rows;
                    this.s_total = res.data.data.total;
                    this.studentList = list;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        // 选中学生
        choose(id){
            this.studentList = this.studentList.map(item=>{
                if(item.id == id&&!item.disabled){
                    item.selected = !item.selected
                }
                return item;
            })
        },
        // 添加、编辑分班
        submit(){
            if(this.type==1){
                this.addClass()
            }else if(this.type==2){
                
            }
        },
        // 添加分班
        addClass(){
            let list = this.studentList.filter(item=>item.selected==true);
            let ids = list.map(item=>{
                return item.id
            })
            this.$api.student.addCcsd({
                distribution_id: this.id,
                student_ids: ids
            }).then(res=>{
                if (res.data.code == 1) {
                    this.$message.success('添加成功');
                    this.getList();
                    this.addVisible = false;
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        delStu(){
            let _this = this;
            if(this.ids.length){
                this.$confirm('确定要删除？','提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(()=>{
                    this.$api.student.delCcsd({
                        id:this.ids
                    }).then(res=>{
                        if (res.data.code == 1) {
                            _this.$message.success('删除成功');
                            _this.getList();
                        } else {
                            _this.$message.error(res.data.msg);
                        }
                    })
                })
            }else{
                this.$message.error('请选择要删除的学生')
            }
        },
        // 全局保存
        save(){
            let list = [];
            for(let i=0; i<this.list.length;i++){
                let obj = {
                    id: this.list[i].id,
                    class_id: this.list[i].class_id,
                    class_num: this.list[i].class_num,
                    remark: this.list[i].remark,
                }
                list.push(obj)
            }
            this.$api.student.editCcsd({
               details: list
            }).then(res=>{
                if (res.data.code == 1) {
                    this.$message.success('保存成功');
                    this.getList();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }
	}
};
</script>

<style lang="scss" scope>
	#student_placement_detail{
       
        .head{
            background-color: #4998ff;
            padding: 15px 20px;
            div{
                color: #fff;
                font-size: 16px;
            }
        }
        .content {
            background-color: #ffffff;
            margin-top: 20px;
            padding: 0 30px;
            height: calc(100% - 100px);
            overflow-y: auto;
            .title {
                padding: 0 0 15px 0;
                .btn{
                    background-color: #f5f5f5;
                    padding: 5px;
                }
                .btn:hover{
                    color: #4998ff;
                    background: #e4f1ff;
                    cursor: pointer;
                }
            }
            .el-table th > .cell,
            .el-table .cell {
                overflow: hidden; /*超出部分隐藏*/
                white-space: nowrap; /*不换行*/
                text-overflow: ellipsis; /*超出部分文字以...显示*/
            }
            .el-table thead {
                background: #fafafa !important;
            }
            .el-table th {
                padding: 15px 5px !important;
                background: #fafafa !important;
            }
            .el-table::before {
                left: 0;
                bottom: 0;
                width: 100%;
                height: 0;
            }
            .el-table .btn{
                padding: 5px;
                text-align: center;
            }
        }
        .el-dialog__body {
            padding: 20px 20px 50px;
            .con-box {
                border-top: 1px solid #eee;
                margin-top: 15px;
                max-height: 400px;
                overflow-y: auto;
                >div{
                    margin-top: 10px;
                    background-color: #F6F8FB;
                    padding: 15px;
                    >div:first-child{
                        margin-right: 30%;
                    }
                }
                >div.none{
                    color: #d8dddf;
                }
                >div.can:hover{
                    color: #4998ff;
                    background: #e4f1ff;
                    cursor: pointer;
                }
                >div.selected,>div.selected.can:hover{
                    background-color: #4998ff;
                    color: #fff;
                }
            }
            .page{
                margin-top: 10px;
                text-align: right;
            }
        }
        .el-dialog__footer {
            padding: 15px 20px 15px;
            box-shadow: 0px -5px 10px -5px #eeeeee;
        }
    }
	
</style>
